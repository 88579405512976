<script lang="ts" setup>
const { workspace } = useAuth()
const { hasTrialSubscription, hasPaidSubscription } = useSubscription()

// Feature is generally enabled, but there is some users excluded via the feature flag
const { enabled: isLawyerChatFeatureEnabled } = useFeatureEnabled('lawyer-chat')
const isChatOmittedAsConsumerWorkspace = computed(() => workspace.value?.attributes.workspaceType === 'consumer' && !hasPaidSubscription.value)

const isLawyerChatEnabled = computed(() => isLawyerChatFeatureEnabled.value && !isChatOmittedAsConsumerWorkspace.value && hasPaidSubscription.value)
</script>

<template>
  <aside
    class="hidden w-60 flex-col border-r border-gray-200 md:flex"
  >
    <AppNavigation class="flex-1 overflow-y-auto px-2.5 py-4" />

    <div
      v-if="isLawyerChatEnabled"
      class="flex flex-col gap-2.5 px-4.5 pb-4 text-gray-800"
    >
      <LawyerChat />
    </div>

    <TrialAlert
      v-if="hasTrialSubscription"
      class="my-2.5"
    />

    <GracePeriodCard
      v-if="!!workspace?.attributes.retention_starts_at && !!workspace?.attributes.grace_starts_at"
      :grace-starts-at="workspace?.attributes.grace_starts_at"
      :grace-ends-at="workspace?.attributes.retention_starts_at"
      class="m-2.5"
    />
  </aside>
</template>
